import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./index.module.css"
import Typo from "../components/typo"
import IconArrowRight from "../images/icon-arrow-right.svg"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className={styles.video}>
        <video
          src={
            data.contentfulLandingVideo.video &&
            data.contentfulLandingVideo.video.file
              ? data.contentfulLandingVideo.video.file.url
              : ``
          }
          playsInline
          autoPlay
          muted
          loop
        ></video>
      </div>
      <div className={styles.text}>
        <div>
          <Typo type="title500" component="h1">
            WE DREAM. <br />
            WE CREATE. <br />
            WE GO BEYOND.
          </Typo>
          <Typo type="text600" component={Link} to="/work">
            Our Work
            <IconArrowRight />
          </Typo>
        </div>
      </div>
      <Typo type="text" component="div" className={styles.foot}>
        &copy; ULTD Ltd.
      </Typo>
    </Layout>
  )}

export default IndexPage

export const query = graphql`
  query {
    contentfulLandingVideo{
      video {
        file {
          url
        }
      }
    }
  }
`